.equipment {
	padding-bottom: 40px;
	&__wrapper {
		display: flex;
		justify-content: space-between;
		@include sm-block {
			flex-direction: column;
			align-items: center; } }
	&__box {
		@include size(4);
		@include size-sm(10);
		@include size-xs(12);
		margin-bottom: 30px;
		text-align: center;
		display: flex;
		flex-direction: column; }
	&__img {
		@include sm-block {
			text-align: center; } }
	&__title {
		color: #a3635b;
		font-size: 1.5rem; }
	&__items {
		padding-left: 20px;
		text-align: left; }
	&__item {
		font-size: 1.25rem;
		line-height: 1.625rem;
		list-style: disc; }
	&__link {
		font-size: 16px;
		color: #a3635b;
		font-weight: bold;
		text-decoration: none;
		margin: 0 30px 0 auto;
		@include sm-block {
			margin: 10px auto 0 0; }
		position: relative;
		transition: all 0.3s;
		&:before {
			content: '';
			width: 0;
			height: 0.1em;
			position: absolute;
			bottom: 0;
			left: 50%;
			transition: all 0.3s; }
		&:hover {
			color: #ED1F2E;
			&:before {
				width: 100%;
				left: 0;
				background: #ED1F23; } } } }


