.contacts {
	padding-bottom: 40px;
	@include sm-block {
		padding-bottom: 20px; }
	&__wrapper {
		display: flex;
		@include sm-block {
			flex-direction: column;
			align-items: center; } }
	&__items {
		@include size(9);
		margin-bottom: 20px;
		@include size-sm(12);
		@include sm-block {
			text-align: center; } }
	&__item {
		line-height: 1.625rem;
		color: #201700;
		padding-bottom: 8px;
		& span {
			display: block;
			color: #555; } }
	&__link {
		font-size: 1.25rem;
		line-height: 1.625rem;
		color: #004FA0;
		text-decoration: none;
		padding-bottom: 8px;
		&:hover {
			color: #ED1F2E; } }
	&__socials {
		@include size(3);
		@include size-sm(12);
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		@include sm-block {
			text-align: center; } }
	&__social {
		&-link {
			font-size: 1.25rem;
			line-height: 1.625rem;
			@include sm-block {
				line-height: 32px; }
			padding-bottom: 8px;
			color: #201700;
			text-decoration: none;
			position: relative;
			&:before {
				content: '';
				width: 40px;
				height: 40px;
				position: absolute;
				top: 50%;
				left: -60px;
				transform: translateY(-50%);
				@include sm-block {
					width: 20px;
					height: 20px;
					left: -28px; } }
			&--vk:before {
				background: url('../img/vk.png') no-repeat;
				background-size: cover; }
			&--instagram:before {
				background: url('../img/instagram.png') no-repeat;
				background-size: cover; }
			&:hover {
				color: #ED1F23; } } } }

