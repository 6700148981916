.video {
	padding: 50px 0 20px 0;
	@include sm-block {
		padding: 30px 0 0 0; }
	&__wrapper {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		& iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; }
		& video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; } }
	&__title {
		font-size: 1.875rem;
		line-height: 2.25rem;
		color: #000111;
		background-color: #e3e4e4;
		text-align: center;
		margin-bottom: 20px;
		padding: 16px 0; } }

