.page {
	padding: 154px 0 40px 0;
	@include sm-block {
		padding-top: 110px; }
	&__wrapper {
		padding: 3% 7% 2%; }
	&__title-h1 {
		color: #9E3230;
		text-align: center;
		font-size: 2.125rem;
		line-height: 2.5rem;
		font-weight: 700;
		padding-bottom: 20px; }
	&__title-h2 {
		color: #39366D;
		font-size: 2rem;
		line-height: 2.25rem;
		padding-top: 44px;
		&--questions {
			text-align: center;
			padding-bottom: 50px; } }
	&__paragraph {
		font-size: 1.375rem;
		line-height: 1.75rem;
		text-indent: 1.25rem;
		margin-top: 18px; }
	&__items {
		margin-top: 10px; }
	&__item {
		font-size: 1.375rem;
		line-height: 1.75rem;
		text-indent: 1.25rem; }
	& img {
		display: block;
		// +size(10)
		// +size-sm(12)
		margin: 24px auto 0; } }
.fa-check {
	padding-right: 10px; }
