.footer {
	min-height: 110px;
	padding: 30px 0 60px 0;
	@include sm-block {
		padding: 30px 0; }
	background-color: #eaf3fc;
	&__wrapper {
		display: flex;
		justify-content: center;
		@include sm-block {
			justify-content: space-between; }
		@include xs-block {
			flex-direction: column;
			align-items: center; } }
	&__items-wrapper {
		@include size(7);
		@include size-sm(9);
		display: flex;
		justify-content: space-around;
		margin-right: auto;
		@include sm-block {
			margin-right: 0; }
		@include xs-block {
			flex-direction: column;
			align-items: center;
			padding-top: 10px; } }
	&__item {
		color: #61648e;
		font-size: 1.25rem;
		line-height: 1.5rem; }
	&__link {
		color: #61648e;
		text-decoration: none;
		position: relative;
		transition: all 0.3s;
		@include xs-block {
			font-size: 16px;
			line-height: 20px; }
		&:before {
			content: '';
			width: 0;
			height: 0.1em;
			position: absolute;
			bottom: 0;
			left: 50%;
			transition: all 0.3s; }
		&:hover {
			color: #ED1F2E;
			&:before {
				width: 100%;
				left: 0;
				background: #ED1F23; } }
		&-social {
			display: inline-block;
			margin-top: 10px;
			@include sm-block {
				width: 30px;
				height: 30px; } } } }
.logo__footer {
	@include size(4);
	@include size-sm(3);
	@include size-xs(12); }
.logo__img--footer {
	@include xs-block {
		margin: 0 auto; } }
