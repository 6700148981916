@import "vars";
@import "smart-grid";

@font-face {
    font-family: 'fontawesome-webfont';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype');
    src: url('../fonts/fontawesome-webfont.woff2') format('woff2');
    src: url('../fonts/fontawesome-webfont.woff') format('woff');
    src: url('../fonts/fontawesome-webfont.ttf') format('truetype');
    src: url('../fonts/fontawesome-webfont.svg#fontawesome-webfont') format('svg'); }

@include reset();
* {
	box-sizing: border-box;
	&:before, &:after {
		box-sizing: border-box; } }
html, body {
	font-size: 1rem;
	@include sm-block() {
		font-size: 0.875rem; }
	@include xs-block() {
		font-size: 0.75rem; }
	height: 100%;
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%; }
body {
	min-width: 320px;
	position: relative;
	line-height: 1.75;
	font-family: Tahoma, Verdana, Sans-Serif;
	opacity: 1;
	@include sm-block() {
		font-size: 0.875rem; } }
img {
	max-width: 100%; }
p {
	text-align: justify; }
.wrapper {
	@include wrapper();
	height: 100%; }
.hidden {
	display: none; }
.form, #popup-close {
	background-color: #fff;
	padding: 40px 30px;
	max-width: 320px;
	margin: auto;
	text-align: center;
	position: relative; }
input {
	width: 100%;
	height: 30px;
	margin-bottom: 10px;
	padding: 0 10px;
	border: 1px solid #ccc;
	color: #84524c; }
.input-error {
	border: 1px solid red; }
input:focus,
textarea:focus {
	outline: none; }
input:invalid {
	background: red;
	color: #fff;
	border: 1px solid red; }
input:focus:required:invalid,
textarea:focus:required:invalid {
	color:red {} }
input:required:valid,
textarea:required:valid {
	color: #666; }
*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }
input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both; }
input::-moz-placeholder,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	opacity: 1;
	color: #b1b1b1;
	text-align: center; }
textarea::-moz-placeholder,
textarea::-webkit-input-placeholder {
	opacity: 1;
	color: #b1b1b1;
	text-align: left !important; }
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
	opacity: 0; }
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
	opacity: 0; }
textarea {
	resize: none;
	width: 100%;
	height: 70px;
	margin-bottom: 2px;
	padding: 4px 10px;
	border: 1px solid #ccc;
	color: #118f8f; }
button:active, button:focus {
	outline: none; }
button::-moz-focus-inner {
	border: 0; }
.button {
	display: block;
	border: none;
	color: #fff;
	text-decoration: none;
	background-color: #84524c;
	padding: 0 20px;
	margin: 0 auto;
	font-size: 1.5rem;
	line-height: 3.25rem;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 3px;
	border-radius: 10px;
	text-align: center;
	outline: none;
	transition: background-color .1s ease;
	cursor: pointer;
	&:hover {
		background-color: lighten(#515c8f, 20%);
		color: #000; }
	&:active {
		background-color: darken(#515c8f, 20%);
		color: #fff; }
	&__popup {
		width: 100%;
		font-size: 1rem;
		line-height: 2.25rem;
		border-radius: 6px; } }
.form__title {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 10px; }
.form__desc {
	line-height: 22px;
	margin-bottom: 20px; }
a:active, a:focus {
	outline: none; }

section, header {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat; }
.h2__title {
	font-size: 2.125rem;
	line-height: 2.5rem;
	color: #61648e;
	text-align: center;
	padding: 24px 0;
	@include sm-block {
		padding: 16px 0; } }
.red-text {
	color: #a3635b;
	font-weight: bold; }

@import "header";
@import "conceptus";
@import "simulatores";
@import "result";
@import "equipment";
@import "video";
@import "contacts";
@import "manikins";
@import "footer";
@import "page";

