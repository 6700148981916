.simulatores {
	&__wrapper {
		display: flex;
		background-color: #f7f6f6;
		margin-bottom: 36px;
		padding: 14px;
		@include sm-block {
			flex-direction: column;
			margin-bottom: 20px; } }
	&__img {
		flex: 1 1 300px;
		display: flex;
		align-items: center;
		@include sm-block {
			flex: 0;
			order: 1;
			justify-content: center; } }
	&__photo {
		display: block; }
	&__info {
		flex: 3 1 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 50px;
		&:nth-child(1) {
			padding-left: 0;
			padding-right: 50px;
			@include sm-block {
				padding-right: 0; } }
		@include sm-block {
			flex: 0;
			padding: 10px 0; } }
	&__title {
		font-size: 1.25rem;
		line-height: normal;
		color: #4c547e;
		text-align: center;
		font-weight: bold;
		margin-bottom: 14px; }
	&__text {
		font-size: 1.125rem;
		line-height: 1.5rem;
		text-indent: 1.25rem; } }

