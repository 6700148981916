.conceptus {
	padding: 154px 0 20px 0;
	@include sm-block {
		padding-top: 110px; }
	&__slider-section {
		background: url('../img/kinezo-bg.jpg') center center no-repeat;
		background-size: cover; }
	&__wrapper {
		display: flex;
		padding: 40px 0;
		@include sm-block {
			padding: 24px 0; } }
	&__slider {
		width: 100%;
		position: relative; }
	&__slide {
		position: relative;
		&-title {
			width: 100%;
			padding: 70px 50px;
			position: absolute;
			top: 0;
			right: 0;
			font-size: 60px;
			line-height: 66px;
			letter-spacing: 0.05em;
			color: #4d64aa;
			text-align: right;
			white-space: pre;
			@include md-block {
				font-size: 44px;
				line-height: 48px; }
			@include sm-block {
				font-size: 36px;
				line-height: 42px;
				padding: 50px 30px; }
			@include xs-block {
				font-size: 24px;
				line-height: 30px;
				padding: 50px 20px; }
			&--left1 {
				padding-top: 120px;
				@include sm-block {
					padding-top: 50px; } }
			&--left2 {
				text-align: left; } } }
	&__info {
		background-color: #eaf3fc;
		padding: 50px 0;
		@include sm-block {
			padding: 30px 0; } }
	&__title {
		color: #515c8f;
		text-align: center;
		font-size: 2.125rem;
		line-height: 2.5rem;
		font-weight: 700;
		padding-bottom: 20px; }
	&__text {
		font-size: 1.375rem;
		line-height: 1.75rem;
		text-indent: 1.25rem;
		margin-bottom: 10px; } }
.slick {
	&-arrow {
		width: 40px;
		height: 80px;
		border: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		text-indent: -9999px;
		cursor: pointer;
		@include sm-block {
			width: 30px;
			height: 60px; }
		@include xs-block {
			width: 20px;
			height: 40px; } }
	&-prev {
		background: url('../img/arrow-prev.png') no-repeat;
		background-size: cover;
		left: 2%;
		z-index: 1; }
	&-next {
		background: url('../img/arrow-next.png') no-repeat;
		background-size: cover;
		right: 2%; }
	&-slide {
		&:focus, a {
    	outline: none; } } }
