.result {
	margin-bottom: 20px;
	padding: 24px 0 50px 0;
	background-color: #eaf3fc;
	@include sm-block {
		padding: 14px 0 30px 0; }
	&__text {
		font-size: 1.375rem;
		line-height: 1.75rem;
		text-indent: 1.25rem;
		margin-bottom: 10px; } }
