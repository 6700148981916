.header {
	width: 100%;
	background-color: #fff;
	position: fixed;
	z-index: 11; }
.top {
	height: 110px;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include sm-block {
		height: 80px;
		box-shadow: 0 16px 16px -16px rgba(0,0,0,0.25); }
	&__phone {
		@include sm-block() {
			padding: 0 20px; }
		@include xs-block() {
			display: none; }
		&--text {
			display: block;
			color: #555;
			text-align: right;
			font-size: 0.875em;
			line-height: 1rem;
			padding: 2px 0 10px 0;
			@include sm-block() {
				text-align: center; }
			&-white {
				color: #fff; } }
		&--link {
			font-size: 1.125rem;
			line-height: 1.5rem;
			color: #004FA0;
			text-align: right;
			text-decoration: none;
			position: relative;
			display: block;
			@include sm-block() {
				text-align: center; }
			&:hover {
				color: #ED1F2E; } }
		&--show {
			width: 300px;
			display: block;
			color: #fff;
			margin: 0 auto;
			&-link {
				color: #fff; } } } }
.logo {
	text-decoration: none;
	text-align: center;
	display: inline-block;
	@include sm-block() {
		line-height: 18px; }
	&__img {
		display: block;
		width: 170px; }
	&__text {
		color: #ED1F2E;
		font-style: italic;
		@include xs-block {
			font-size: 14px; } } }
.menu {
	width: 100%;
	background-color: #eaf3fc;
	box-shadow: 0 16px 16px -16px rgba(0,0,0,0.25);
	@include sm-block() {
		display: none; } }
.nav {
	height: 44px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	&__top {
		display: flex;
		text-transform: uppercase;
		justify-content: space-between; }
	&__link {
		color: #6574b4;
		text-decoration: none;
		letter-spacing: 0.14em;
		font-weight: 600;
		display: inline-block;
		padding: 0 14px;
		position: relative;
		transition: all 0.3s;
		&:before {
			content: '';
			width: 0;
			height: 0.1em;
			position: absolute;
			bottom: 0;
			left: 50%;
			transition: all 0.3s; }
		&:hover {
			color: #ED1F2E;
			&:before {
				width: 100%;
				left: 0;
				background: #ED1F23; } } } }
#hamburger {
	width: 44px;
	height: 36px;
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	z-index: 13;
	display: none;
	@include sm-block() {
		display: block; }
	& span {
		display: block;
		position: absolute;
		height: 6px;
		width: 100%;
		background: #ED1F23;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
		&:nth-child(1) {
			top: 0px; }
		&:nth-child(2) {
			top: 14px; }
		&:nth-child(3) {
			top: 28px; } }
	&.open span:nth-child(1) {
		top: 15px;
		transform: rotate(135deg); }
	&.open span:nth-child(2) {
		opacity: 0;
		left: -50px; }
	&.open span:nth-child(3) {
		top: 15px;
		transform: rotate(-135deg); } }
.hidden-menu {
	position: fixed;
	width: 0;
	height: 100%;
	background-color: #004FA4;
	z-index: 12;
	top: 0;
	right: 0;
	transition: .3s ease-in-out;
	&.open {
		width: 100%; }
	&__items {
		padding: 20px 70px 30px 30px; }
	&__item {
		border-bottom: 1px solid #ED1F23; }
	&__link {
		color: #fff;
		font-size: 14px;
		line-height: 44px;
		text-transform: uppercase;
		text-decoration: none;
		letter-spacing: 0.14em;
		font-weight: 600;
		display: block;
		padding-left: 10px;
		transition: all 0.3s;
		&:hover {
			background-color: #ED1F23; } } }
